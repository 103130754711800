
<template>
  <header id="page-topbar">
    <div class="navbar-header">
      <div class="d-flex">
        <!-- LOGO -->
        <div class="navbar-brand-box">
          <router-link tag="a" to="/home" class="logo logo-dark">
            <span class="logo-sm">
              <img src="@/assets/images/logo.png" alt height="35" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo.png" alt height="60" />
            </span>
          </router-link>

          <router-link tag="a" to="/home" class="logo logo-light">
            <span class="logo-sm">
              <img src="@/assets/images/logo.png" alt height="35" />
            </span>
            <span class="logo-lg">
              <img src="@/assets/images/logo.png" alt height="60" />
            </span>
          </router-link>
        </div>

        <button
          type="button"
          class="btn btn-sm px-3 font-size-24 header-item"
          id="vertical-menu-btn"
          @click="toggleMenu()"
        >
          <i class="mdi mdi-menu"></i>
        </button>

      </div>

      <div class="d-flex">

        <div class="dropdown d-none d-lg-inline-block">
          <button type="button" class="btn header-item noti-icon" @click="initFullScreen">
            <i class="mdi mdi-fullscreen"></i>
          </button>
        </div>

        <b-dropdown
          class="d-inline-block"
          toggle-class="header-item noti-icon"
          menu-class="dropdown-menu-lg p-0"
          right
          variant="white"
        >
          <template v-slot:button-content>
            <i class="mdi mdi-bell-outline"></i>
            <span class="badge badge-danger badge-pill" v-if="notification.unread > 0">{{ notification.unread }}</span>
          </template>
          <div class="p-3">
            <div class="row align-items-center">
              <div class="col">                
                <div class="float-right font-size-18">
                  <i class="mdi mdi-eye-check-outline" v-b-tooltip.hover title="Read All Notification" @click="readAll"></i>
                  <!-- <i class="mdi mdi-trash-can-outline" v-b-tooltip.hover title="Clear Notification"></i> -->
                </div>
                <h5 class="m-0 font-size-16">Notifications <span  v-if="notification.unread > 0">({{ notification.unread }})</span></h5>
              </div>
            </div>
          </div>
          <div data-simplebar style="max-height: 230px;" v-if="notification.list.length > 0">
            <a v-for="(notif, index) in notification.list" :key="index" href="#" @click="clickNotification(notif)" class="text-reset notification-item">
              <div class="media">
                <div class="avatar-xs mr-3">
                  <span class="avatar-title bg-success rounded-circle font-size-16">
                    <i class="mdi mdi-calendar"></i>
                  </span>
                </div>
                <div class="media-body">
                  <h6 class="mt-0 mb-1">{{ notif.title }}</h6>
                  <div class="font-size-12 text-muted">
                    <p class="mb-1">{{ notif.body }}.</p>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </b-dropdown>

        <b-dropdown class="d-inline-block" right toggle-class="header-item" variant="white">
          <template v-slot:button-content>
            <img
              class="rounded-circle header-profile-user"
              src="@/assets/images/users/user-4.jpg"
              alt="Header Avatar"
            />
          </template>
          <!-- <a class="dropdown-item" href="/profile">
            <i class="mdi mdi-account-circle font-size-17 align-middle mr-1"></i> Profile
          </a> -->
          <!-- <a class="dropdown-item" href="#">
            <i class="mdi mdi-wallet font-size-17 align-middle mr-1"></i> My Wallet
          </a>
          <a class="dropdown-item d-block" href="#">
            <span class="badge badge-success float-right">11</span>
            <i class="mdi mdi-settings font-size-17 align-middle mr-1"></i> Settings
          </a> -->
          <!-- <a class="dropdown-item" href="#">
            <i class="mdi mdi-lock-open-outline font-size-17 align-middle mr-1"></i> Lock screen
          </a> -->
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" @click.prevent="logout()" href="#" >
            <i class="bx bx-power-off font-size-17 align-middle mr-1 text-danger"></i> Logout
          </a>
        </b-dropdown>

      </div>
    </div>
  </header>
</template>

<script>

/**
 * Topbar component
 */
import cookie from "js-cookie";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      notification: {
        unread: 0,
        list: []
      }
    };
  },
  mounted() {
    //this.getNotifications()
  },
  // sockets: {
  //   appointment: function(data) {
  //     console.log('message from socket', data);
  //     this.getNotifications();
  //   }
  // },
  methods: {
    getNotifications() {
      this.$axios.get(`notification/resume`)
        .then( response => {
          console.log(response.data)
          let result = response.data.result
          this.notification.unread = result.unread
        }).catch( error => {
          console.log(error.response)
        })
      this.$axios.get(`notification`, {
        params: {
          read: 0
        }
      })
        .then( response => {
          console.log(response.data)
          let result = response.data.result
          this.notification.list = result.notifications
        }).catch( error => {
          console.log(error.response)
        })
    },
    readAll() {
      this.$axios.put(`notification/read/all`)
        .then( response => {
          console.log(response.data)
          this.getNotifications()
        }).catch( error => {
          console.log(error.response)
        })
    },
    clickNotification(notification) {
      this.$axios.put(`notification/${notification.notificationId}/read`)
        .then( response => {
          console.log(response.data)
          this.getNotifications()
        }).catch( error => {
          console.log(error.response)
        })
      if (notification.appointment.checkout === false) {
        this.$router.push(`/appointment/edit/${notification.appointment._id}`)
      } else {
        this.$router.push(`/appointment?show=${notification.appointment._id}`)
      }
    },
    logout(){
      Swal.fire({
        title: "Are you sure want to logout ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#34c38f",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, let me quit"
      }).then(result => {
        if (result.value) {
          cookie.remove("userdata");
          this.$store.commit("SET_LOGIN", null);
          this.$router.push("/");
        }
      });
    },
    initFullScreen() {
      document.body.classList.toggle("fullscreen-enable");
      if (
        !document.fullscreenElement &&
        /* alternative standard method */ !document.mozFullScreenElement &&
        !document.webkitFullscreenElement
      ) {
        // current working methods
        if (document.documentElement.requestFullscreen) {
          document.documentElement.requestFullscreen();
        } else if (document.documentElement.mozRequestFullScreen) {
          document.documentElement.mozRequestFullScreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
          document.documentElement.webkitRequestFullscreen(
            Element.ALLOW_KEYBOARD_INPUT
          );
        }
      } else {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    toggleMenu() {
      this.$parent.toggleMenu();
    },
    toggleRightSidebar() {
      this.$parent.toggleRightSidebar();
    }
  }
};
</script>